.app {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0D2A4A; /* Cor de fundo */
}

.app-logo {
  width: 80%; /* Ajuste a largura para dispositivos móveis */
  max-width: 500px; /* Largura máxima para desktop */
  margin-bottom: 20px;
}

.app-header h1 {
  font-size: 2rem;
  color: #ffffff; /* Cor do texto em branco */
  margin: 0; /* Remove a margem padrão */
}

.app-header p {
  font-size: 1.2rem; /* Tamanho do texto do parágrafo */
  color: #ffffff; /* Cor do texto em branco */
  max-width: 600px; /* Largura máxima para o parágrafo */
  line-height: 1.5; /* Espaçamento entre linhas */
  padding: 0 20px; /* Espaçamento lateral */
  text-align: justify; /* Justifica o texto */
}

.app-footer {
  margin-top: auto; /* Faz o rodapé ficar na parte inferior */
  padding: 20px; /* Espaçamento interno */
  background-color: #0D2A4A; /* Mesma cor de fundo para harmonizar */
  color: #ffffff; /* Cor do texto em branco */
  text-align: justify; /* Justifica o texto */
  font-size: 0.9rem; /* Tamanho de fonte menor */
}

/* Media Queries para dispositivos móveis */
@media (max-width: 768px) {
  .app-header h1 {
    font-size: 1.5rem; /* Reduz o tamanho do título */
  }

  .app-header p {
    font-size: 1rem; /* Reduz o tamanho do texto do parágrafo */
    padding: 0 10px; /* Reduz o espaçamento lateral */
  }

  .app-footer {
    font-size: 0.8rem; /* Tamanho de fonte menor para rodapé */
  }
}
